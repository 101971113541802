<template>
  <div class="row">
    <div class="col-md-3">
      <b-calendar v-model="date" v-on:input="changeDate" :date-info-fn="dateClass" block start-weekday="1"></b-calendar>
    </div>
    <div class="col-md-9 mt-3 mt-md-0">
      <b-card no-body>
        <b-card-header>
          {{ rosterId }}: {{ fromDate }} &ndash; {{ toDate }}
          <b-badge variant="info" pill>{{ shifts.length }}</b-badge>
        </b-card-header>

        <b-card-body v-if="loading">
          <b-spinner variant="info"/>
        </b-card-body>

        <b-card-body v-if="!loading && shifts.length === 0" class="text-center font-italic small text-muted">
          Je bent deze periode niet ingepland.
        </b-card-body>

        <b-list-group flush v-if="!loading && shifts.length > 0">
          <b-list-group-item v-for="shift in shifts" :key="shift.id">
            <div class="row">
              <div class="col-md-2">
                <span class="text-muted small">{{ datehelper(shift.start).getWeekday() }}&nbsp;</span>
                <span class="text-muted small">{{ datehelper(shift.start).formatDate() }}</span>
              </div>
              <div class="col-md-5">
                <router-link :to="{ name: 'Event', params: { id: shift.event.id }}" v-if="shift.event.id != null">{{ shift.event.name }}</router-link>
                <span v-else>{{ shift.event.name }}</span>
                <b-badge variant="info" class="ml-1" v-if="shift.unpublished">Ongepubliceerd</b-badge>
                <br />
                <span class="text-muted small">{{ shift.role }}</span>
              </div>
              <div class="col-md-5">
                <span class="text-info font-weight-bold">{{ datehelper(shift.start).formatTime() }}</span>
                &ndash;
                <span>{{ datehelper(shift.end).formatTime() }}</span>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <div class="mt-3 text-center">
        <button class="btn btn-info btn-sm" @click="logout">Rooster uitloggen</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
import {datehelper} from "@/helpers/datehelper";

export default {
  data() {
    return {
      date: new Date().toDateString(),
      shifts: [],
      loading: true,
      dates: []
    }
  },
  computed: {
    ...mapGetters(['rosterId']),
    toDate() {
      return datehelper(this.date).addDays(13).formatDate()
    },
    fromDate() {
      return datehelper(this.date).formatDate()
    },
    redirectDate() {
      return datehelper(this.date).formatRevDate()
    }
  },
  methods: {
    ...mapActions(['apiGet']),
    datehelper,
    updateRoster() {
      this.loading = true
      this.shifts = []

      this.apiGet("resourcebookings/date:"+ this.fromDate +" TO "+ this.toDate +" name:"+ this.rosterId +" type:human").then((result) => {
        this.shifts = result.data.data
        this.loading = false
      })

      // Fill the calendar dates
      const fd = datehelper(this.date).subtractDays(31).formatDate()
      const td = datehelper(this.date).addDays(31).formatDate()
      this.apiGet("resourcebookings/date:"+ fd +" TO "+ td +" name:"+ this.rosterId +" type:human").then((result) => {
        this.dates = []
        result.data.data.forEach((item) => {
          let dt = new Date(item.start)
          let m = (dt.getMonth() + 1 < 10) ? "0"+ (dt.getMonth() + 1) : dt.getMonth() + 1
          let d = (dt.getDate() < 10) ? "0"+ dt.getDate() : dt.getDate()
          this.dates.push(dt.getFullYear() +"-"+ m +"-"+ d)
        })
      })
    },
    changeDate() {
      if(this.$route.params.date !== this.date) {
        this.$router.push({name: 'Rooster', params: {date: this.date}})
        this.updateRoster()
      }
    },
    dateClass(ymd) {
      return (this.dates.includes(ymd)) ? "table-info" : ""
    },
    logout() {
      if(confirm('Weet je zeker dat je het rooster wil uitloggen?')) {
        this.$store.commit("clearRosterId")
        localStorage.removeItem("rosterid")
        this.$router.push({name: 'Rooster Login'})
      }
    }
  },
  mounted() {
    if(this.$route.params.date != null) {
      if(this.$route.params.date === 'today') {
        this.$router.push({ name: 'Rooster', params: { date: this.redirectDate } })
      } else {
        this.date = this.$route.params.date
      }
    }

    if(this.rosterId == null || this.rosterId === "") {
      this.$router.push({ name: 'Rooster Login' })
    }
    this.updateRoster()
  }
}
</script>